.curve {
  position: absolute;
  z-index: -999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.slide-enter,
.slide-exit {
  transition: transform 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter.slide-enter-active {
  transform: translateX(0%);
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(0%);
}

.slide-exit-active {
  transform: translateX(-100%);
}
