h1 {
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 1.953rem;
}
h2 {
    margin-top: 6px;
    margin-bottom: 6px;
    font-size: 1.563rem;
}
h3 {
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: 1.25rem;
}